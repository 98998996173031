.map-container {
  height: 100vh;
  width: 100%;
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sale-script-text {
  font-weight: 400;
  color: #181818;
  font-size: 18px;
  margin: 0;
}

.sale-script-action-button {
  background-color: #8c8c8c;
  padding: 10px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 10px;
  border: 1px solid #bebebe;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sale-script-action-button-active {
  background-color: #93c479;
  padding: 10px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 10px;
  border: 1px solid #bebebe;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sale-script-action-button:hover {
  background-color: rgb(108, 108, 108);
}

.sale-script-action-button-active:hover {
  background-color: rgb(108, 108, 108);
}

.table-responsive {
  overflow-x: auto; /* Allows horizontal scrolling */
}

.fixed-column {
  position: sticky;
  left: 0;
  background-color: #fff; /* Ensures the column background stays visible */
  z-index: 1; /* Ensures it stays on top of other columns when scrolling */
  border-right: 1px solid #dee2e6; /* Adds a border between the fixed column and other columns */
}

.no-padding-left {
  padding-left: 0 !important;
}